import React, {useState, useEffect} from "react";
import Web3 from 'web3';
import '../Staking/staking.css'
import bannerVideo from "../../assets/images/banner-video.mp4";
import {useNavigate} from "react-router-dom";
import {Footer} from "../Footer";

interface AppProps {
}

declare global {
    interface Window {
        ethereum?: any;
    }
}

function Home(props: AppProps) {
    const {ethereum} = window as any;
    const [chain, setchain] = useState(0);
    const [address, setaddress] = useState("");
    const get_data = async () => {
        const web3 = new Web3(Web3.givenProvider);
        const chainId = await web3.eth.getChainId();
        setchain(chainId);
        window.ethereum.on('chainChanged', get_data);
        if (chainId === 137) {
            setaddress("0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4");
        } else if (chainId === 56) {
            setaddress("0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF");
        } else if (chainId === 71402) {
            setaddress("0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF");
        }
        setchain(chainId);
    }

    useEffect(() => {
        if (ethereum) {
            get_data();
        }
    }, []);

    const navigate = useNavigate();
    const handleminting = () => {
        navigate('/Mint');
    };

    return (
        <>
            <section className="main-banner p0">
                {/*<div className="wrapper">*/}
                <video className="banner-video" autoPlay loop>
                    <source src={bannerVideo} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                <div className="videocaption">
                    <div className="wrapper text-center flex-col">
                        <h1 className="heading">
                            NFT COMICS ClUB
                        </h1>
                        <p>The Rise of the Nano Titan, A Saga of Power and Chaos</p>
                        <br/>
                        <button className="--btn-whitepaper p4" onClick={handleminting}>
                            Mint Now
                        </button>

                    </div>

                </div>
                {/*</div>*/}
            </section>
            <section className="about-project">
                <div className="wrapper">
                    <div className="text-center">
                        <h1 className="heading">
                            About Project
                        </h1>
                        <p>Embark on the future of storytelling with NFTComics.club! We're crafting a revolutionary
                            platform that aims to empower writers to self-publish using NFT-based e-books. Our vision
                            includes an Author's Launchpad and an innovative e-book marketplace to redefine digital
                            literature. Stay tuned as we work towards bringing these exciting features to life and
                            shaping the future of immersive storytelling. Join us on this journey! in the meantime,
                            enjoy our first graphic novel.</p>
                        <br/>

                    </div>
                </div>
            </section>
            {/*<section className="team-member">*/}
            {/*    <h2 className="heading text-center mb-10">Team Member</h2>*/}
            {/*    <div className="wrapper">*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team1} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Techmeup</h4>*/}
            {/*                <p>CEO</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team2} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Alexander</h4>*/}
            {/*                <p>Manager</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team3} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Bjorn</h4>*/}
            {/*                <p>Development Head</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="how-section">
                <h2 className="heading text-center mb-10">How to</h2>
                <div className="wrapper">
                    <div className="step-card">
                        <h2 className="heading">01</h2>
                        <span>Step</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam obcaecati quos ratione
                            repellat tempora. Accusantium ad assumenda, autem, blanditiis, corporis doloribus eaque
                            expedita id nesciunt quae saepe ullam velit voluptas.</p>
                    </div>
                    <div className="step-card">
                        <h2 className="heading">02</h2>
                        <span>Step</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam obcaecati quos ratione
                            repellat tempora. Accusantium ad assumenda, autem, blanditiis, corporis doloribus eaque
                            expedita id nesciunt quae saepe ullam velit voluptas.</p>
                    </div>
                    <div className="step-card">
                        <h2 className="heading">03</h2>
                        <span>Step</span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam obcaecati quos ratione
                            repellat tempora. Accusantium ad assumenda, autem, blanditiis, corporis doloribus eaque
                            expedita id nesciunt quae saepe ullam velit voluptas.</p>
                    </div>
                </div>
            </section>
            {/*<section className="partners">*/}
            {/*    <div className="heading text-center mb-10">Our Partners</div>*/}
            {/*    <div className="wrapper">*/}
            {/*        <ul>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <img src={bannerimg} alt=""/>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <Footer/>
            {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>*/}
            {/*    */}
            {/*</div>*/}
        </>
    );
}

export default Home;