import React, {useState, useEffect, ChangeEvent} from 'react';
import './staking.css'
import nftstaking from '../../Abi/nftstaking.json'
import IERC20 from '../../Abi/IERC20.json'
import punks from '../../Abi/punk.json'
import Ncc from '../../Abi/Ncc_club.json'
import {AbiItem} from "web3-utils";
import axios from 'axios';
import Web3 from "web3";
import {Footer} from "../Footer";
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';

function Staking() {
    const {ethereum} = window as any;
    const [user_ipfswallet, set_user_ipfswallet] = useState<any[]>([]);
    const [stake_nft, set_stake_nft] = useState<any[]>([]);
    const [token_id, set_token_id] = useState<any[]>([]);
    const [currest_stk_balance, set_currest_stk_balance] = useState('');
    const [interest, set_interest] = useState('');
    const [chain, setchain] = useState(0);

    useEffect(() => {
        if (ethereum) {
            get_campign();
        }
    }, []);

    const get_campign = async () => {
        let array: any[] = [];
        let Stake_array: any[] = [];

        const web3 = new Web3(Web3.givenProvider);
        const abi = punks;
        const IERC20_abi = IERC20.abi;
        const accounts = await web3.eth.getAccounts((error: any, accounts: any) => {
            if (!error && accounts.length > 0) {
                const userAddress = accounts[0];
                console.log(`User's Ethereum address: ${userAddress}`);
            } else {
                console.error('Error getting user address:', error);
            }
        });

        if (!accounts || accounts.length === 0) {
            return;
        }

        const chainId = await web3.eth.getChainId();
        setchain(chainId);
        window.ethereum.on('chainChanged', get_campign);
        if (chainId === 137) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xEAE202B0D0656dD42E535a43712206c0DF0B3A3c')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                console.log(token);
                for (let j = 0; j < token; j++) {
                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push({"id": tokenId, "image": content});

                        });
                    });
                }
                set_token_id(token);
            });
            set_stake_nft(Stake_array)

            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });

            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {
                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            array.push({"id": tokenId, "image": content});
                        });
                    });
                }
            });
            set_user_ipfswallet(array);
        } else if (chainId === 56) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xc5fbEA272748b0d6aE4C7d3D3F6B5f25c9f5db03')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                console.log(token);
                for (let j = 0; j < token; j++) {

                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push({"id": tokenId, "image": content});

                        });
                    });


                }


                set_token_id(token);
            });
            set_stake_nft(Stake_array)


            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });


            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {

                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            console.log(content);
                            array.push({"id": tokenId, "image": content});

                        });
                    });

                }

            });


            set_user_ipfswallet(array);
        } else if (chainId === 71402) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xA440C9a6cBbADCF0509C555939b5Cc07748f8e66')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                for (let j = 0; j < token; j++) {
                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push({"id": tokenId, "image": content});
                        });
                    });
                }
                set_token_id(token);
            });
            set_stake_nft(Stake_array)

            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });

            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {
                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            console.log(content);
                            array.push({"id": tokenId, "image": content});

                        });
                    });
                }
            });
            set_user_ipfswallet(array);
        }
        setchain(chainId);
    }

    const stake = async (tokenid: any) => {
        if (chain === 137) {
            const web3 = new Web3(Web3.givenProvider);

            const nftstaking_abi = nftstaking;
            const punks_abi = punks;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e')
            const punks_contract = new web3.eth.Contract(punks_abi as AbiItem[], '0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4')

            punks_contract.methods.approve('0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e', tokenid).send({from: accounts[0]}).on('receipt', (receipt: any) => {
                nftstaking_abi_contract
                    .methods
                    .stake(tokenid)
                    .send({from: accounts[0], gas: '5000000', value: '0'})
                    .once('receipt', (receipt: any) => {
                        window.location.reload();
                    })
                    .on("error", (error: any) => {
                        console.log(error)
                    });
            })
        } else if (chain === 56) {
            console.log(tokenid);
            const web3 = new Web3(Web3.givenProvider);


            const nftstaking_abi = nftstaking;
            const punks_abi = punks;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')
            const punks_contract = new web3.eth.Contract(punks_abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')


            punks_contract.methods.approve('0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902', tokenid).send({from: accounts[0]}).on('receipt', (receipt: any) => {
                nftstaking_abi_contract
                    .methods
                    .stake(tokenid)
                    .send({from: accounts[0], gas: '5000000', value: '0'})
                    .once('receipt', (receipt: any) => {
                        window.location.reload();
                    })
                    .on("error", (error: any) => {
                        console.log(error)

                    });

            })

        } else if (chain === 71402) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const punks_abi = punks;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')
            const punks_contract = new web3.eth.Contract(punks_abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')

            punks_contract.methods.approve('0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902', tokenid).send({from: accounts[0]}).on('receipt', (receipt: any) => {
                nftstaking_abi_contract
                    .methods
                    .stake(tokenid)
                    .send({from: accounts[0], gas: '5000000', value: '0'})
                    .once('receipt', (receipt: any) => {
                        window.location.reload();
                    })
                    .on("error", (error: any) => {
                        console.log(error)

                    });
            })
        }
    }

    const withdraw = async (tokenid: any) => {
        if (chain === 137) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e')

            nftstaking_abi_contract
                .methods
                .unstake(tokenid)
                .send({from: accounts[0], gas: '5000000', value: '0'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });
        } else if (chain === 56) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            nftstaking_abi_contract
                .methods
                .unstake(tokenid)
                .send({from: accounts[0], gas: '5000000', value: '0'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();


                })
                .on("error", (error: any) => {
                    console.log(error)

                });
        } else if (chain === 71402) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            nftstaking_abi_contract
                .methods
                .unstake(tokenid)
                .send({from: accounts[0], gas: '5000000', value: '0'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });
        }
    }

    const withdraw_without_unstaking = async (tokenid: any) => {
        if (chain === 137) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e')

            nftstaking_abi_contract
                .methods
                .withdrawAllInterestFromATokenWithoutUnstaking(tokenid)
                .send({from: accounts[0], gas: '5000000',})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });

        } else if (chain === 56) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            nftstaking_abi_contract
                .methods
                .withdrawAllInterestFromATokenWithoutUnstaking(tokenid)
                .send({from: accounts[0], gas: '5000000',})
                .once('receipt', (receipt: any) => {
                    window.location.reload();


                })
                .on("error", (error: any) => {
                    console.log(error)

                });
        } else if (chain === 71402) {
            const web3 = new Web3(Web3.givenProvider);
            const nftstaking_abi = nftstaking;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking_abi as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            nftstaking_abi_contract
                .methods
                .withdrawAllInterestFromATokenWithoutUnstaking(tokenid)
                .send({from: accounts[0], gas: '5000000',})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });
        }
    }

    return (
        <div className="staking-page">
            <section className="section">
                <h1>Your Staked NFTs</h1>
                <div className='nft-wrap'>
                    {stake_nft.length > 0 ? (
                        stake_nft.map((value, index) => (
                            <div key={index} className="nft-card">
                                <img src={"https://ipfs.io/" + value.image}/>
                                <div className='nft-card-bottom'>
                                    <button type="button" onClick={() => withdraw(value.id)} className='--btn'>
                                        Unstake NFTs
                                    </button>
                                    <button type="button" onClick={() => withdraw_without_unstaking(value.id)}
                                            className='--btn'>
                                        Claim Tokens
                                    </button>
                                    <ul>
                                        <li>
                                            <Link style={{color: 'white'}} to="/Book">View Book</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="empty-message" style={{textAlign: 'center', margin: 'auto'}}>
                            <h4 style={{color: '#626161'}}>
                                Your staked books
                            </h4>
                        </div>
                    )}
                </div>
            </section>
            <section className="section">
                <h1>Your Unstaked NFTs </h1>
                <div className='nft-wrap'>
                    {user_ipfswallet.length > 0 ? (
                        user_ipfswallet.map((value, index) => (
                            <div key={index} className="nft-card">
                                <img src={"https://ipfs.io/" + value.image} alt={`NFT ${index}`}/>
                                <div className='nft-card-bottom'>
                                    <button type="button" onClick={() => stake(value.id)} className='--btn'>
                                        Stake
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="empty-message" style={{textAlign: 'center', margin: 'auto'}}>
                            <h4 style={{color: '#626161'}}>
                                No books available and your unstaked book
                            </h4>
                        </div>
                    )}
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default Staking;