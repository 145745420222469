import React from "react";

export function Footer() {
    return (
        <footer>
            <div className="wrapper">
                <p>&copy; 2023 - All rights reversed. </p>
                <ul className="social-link">
                    <li>
                        <a href="https://medium.com/@nftcc">
                            <i className="fa-brands fa-medium-m"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://bsky.app/profile/nftcomicsclub.bsky.social">
                            <i className="fa-solid fa-box"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/NFTComicsclub">
                            <i className="fa-brands fa-twitter"/>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

