import {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet, useNavigate } from 'react-router-dom';
import {ethers} from 'ethers';
import logo from '../../assets/images/cover.png';
import Web3 from "web3";

function Header() {
    const [haveMetamask, sethaveMetamask] = useState(true);
    const [accountAddress, setAccountAddress] = useState('');
    const [accountBalance, setAccountBalance] = useState('');
    const [isConnected, setIsConnected] = useState(false);

    const {ethereum} = window as any;
   
    useEffect(() => {
        if (ethereum) {
            const initializeMetamask = async () => {
                const web3 = new Web3(Web3.givenProvider);
                const accounts = await web3.eth.getAccounts((error, accounts) => {
                    if (!error && accounts.length > 0) {
                        const userAddress = accounts[0];
                        // User has authorized access to their accounts
                        const address = accounts[0];
                        // Wallet is connected
                        setAccountAddress(accounts[0]);
                        setIsConnected(true);
                    } else {
                        console.error('Error getting user address:', error);
                    }
                });
            };
            // Call the initialization function
            initializeMetamask();
        }

       
        // Your additional logic for both scenarios
        // get_campign();
    },);

    useEffect(() => {
        const handleClick = () => {
            const header = document.querySelector("header");
            if (header) {

                console.log(header)
                header.classList.toggle("menu-isopen");
            }
        };

        const btnMenu = document.querySelector(".btnmenu");
        if (btnMenu) {
            btnMenu.addEventListener("click", handleClick);
        }

        return () => {
            // Cleanup: Remove the event listener when the component unmounts
            if (btnMenu) {
                btnMenu.removeEventListener("click", handleClick);
            }
        };
    }, []);

    const checkMetamaskAvailability = async () => {
        if (!ethereum) {
            sethaveMetamask(false);
        }
        sethaveMetamask(true);


    };

    const connectWallet = async () => {
        try {
            if (!ethereum) {
                sethaveMetamask(false);
                setIsConnected(false); // Ensure isConnected is set to false
            } else {
                const accounts = await ethereum.request({
                    method: 'eth_requestAccounts',
                });
                if (accounts.length > 0) {
                    // Wallet is connected
                     const provider = new ethers.providers.Web3Provider((window as any).ethereum);

                    let balance = await provider.getBalance(accounts[0]);
                    let bal = ethers.utils.formatEther(balance);
                    setAccountAddress(accounts[0]);
                    setAccountBalance(bal);
                    setIsConnected(true);
                    // Reload the screen
                    window.location.reload();
                } else {
                    // No accounts provided by the user, so the wallet is not connected.
                    setIsConnected(false);
                }
            }
        } catch (error) {
            console.error('Error connecting wallet:', error);
            setIsConnected(false);
        }
    };


    const handleWhitepaper = () => {
        const whitepaperLink = 'https://drive.google.com/file/d/19iRv_oRJm4K34sL5UdabO7sdiF5PMqS9/view?usp=sharing';
        window.open(whitepaperLink, '_blank');
    };


    return (
        <div>
            
                <header>
                    <div className="logo">
                       <Link to="/">
                           <img style={{height: '126px'}} src={logo} alt="logo" className='logo'/>
                       </Link>
                        <h1 style={{color: 'white'}}>NFT COMICS CLUB</h1>
                    </div>
                    <button className="btnmenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <nav style={{marginLeft:'auto',marginRight:'1rem'}}>
                        <ul style={{display: 'flex', listStyle: 'none', margin: 0, padding: 0}}>
                            <li style={{color: 'white', textDecoration: 'none', margin: 20}}>
                                <Link to="/">Mint</Link>
                            </li>
                            <li style={{color: 'white', textDecoration: 'none', margin: 20}}>
                                <Link to="/Staking">Staking / Reading</Link>
                            </li>
                        </ul>
                    </nav>

                    <div>
                        <button className="--btn-whitepaper" onClick={handleWhitepaper}>
                            Whitepaper
                        </button>
                        {isConnected ? (
                            <button className="--btn">
                                {accountAddress.slice(0, 4)}...
                                {accountAddress.slice(38, 42)}
                            </button>
                        ) : (
                            <button className="--btn" onClick={connectWallet}>
                                Connect Wallet
                            </button>
                        )}
                    </div>
                </header>
           
        </div>
    );
}

export default Header;