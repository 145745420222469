import React, {useState, useEffect} from "react";
import Web3 from 'web3';
import '../Staking/staking.css'
import nftstaking from '../../Abi/nftstaking.json'
import punks from '../../Abi/punk.json'
import {AbiItem} from "web3-utils";
import bannerimg from "../../assets/images/home-banner-com.png";
import {Footer} from "../Footer";

interface AppProps {
}

declare global {
    interface Window {
        ethereum?: any;
    }
}

function Home(props: AppProps) {
    const [totalSupply, settotalSupply] = useState(0);
    const [cost, setcost] = useState('');
    const [symbol, setsymbol] = useState('');
    const {ethereum} = window as any;
    const [chain, setchain] = useState(0);
    const [address, setaddress] = useState("");
    const get_data = async () => {
        const web3 = new Web3(Web3.givenProvider);
        const chainId = await web3.eth.getChainId();
        setchain(chainId);
        window.ethereum.on('chainChanged', get_data);
        if (chainId === 137) {
            setaddress("0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4");
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(punks as AbiItem[], '0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4')


            const _totalSupply = await punks_contract.methods.totalSupply().call().then(async (balance: any) => {
                settotalSupply(balance);
            });
            const _cost = await punks_contract.methods.cost().call().then(async (wei: any) => {
                var balance = web3.utils.fromWei(wei, 'ether');
                setcost(balance);
            });
            setsymbol('Matic');
        } else if (chainId === 56) {
            setaddress("0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF");
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(punks as AbiItem[], '0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF')


            const _totalSupply = await punks_contract.methods.totalSupply().call().then(async (balance: any) => {
                settotalSupply(balance);
            });
            const _cost = await punks_contract.methods.cost().call().then(async (wei: any) => {
                var balance = web3.utils.fromWei(wei, 'ether');
                setcost(balance);


            });
            setsymbol('BNB');
        } else if (chainId === 71402) {
            setaddress("0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF");
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(punks as AbiItem[], '0x324C749bF4BE4bB07Be5D3CD4ff2147D24ACbfBF')
            const _totalSupply = await punks_contract.methods.totalSupply().call().then(async (balance: any) => {
                settotalSupply(balance);
            });
            const _cost = await punks_contract.methods.cost().call().then(async (wei: any) => {
                var balance = web3.utils.fromWei(wei, 'ether');
                setcost(balance);
            });
            setsymbol('PCKB');
        }
        setchain(chainId);
    }
    useEffect(() => {
        if (ethereum) {
            get_data();
        }

    }, []);

    const mint = async () => {

        const web3 = new Web3(Web3.givenProvider);
        let accounts;

        try {
            accounts = await ethereum.request({
                method: 'eth_requestAccounts',
            });
        } catch (error) {
            alert('Please connect to MetaMask to interact with the application.');
            return;
        }

        const chainId = await web3.eth.getChainId();
        const punks_contract = new web3.eth.Contract(punks as AbiItem[], address)

        if (chainId === 137) {
            punks_contract
                .methods
                .mint(1)
                .send({from: accounts[0], gas: '5000000', value: '33000000000000000000'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)

                });
        } else if (chainId === 56) {
            punks_contract
                .methods
                .mint(1)
                .send({from: accounts[0], gas: '5000000', value: '110000000000000000'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });
        } else if (chainId === 71402) {
            punks_contract
                .methods
                .mint(1)
                .send({from: accounts[0], gas: '5000000', value: '8000000000000000000000'})
                .once('receipt', (receipt: any) => {
                    window.location.reload();
                })
                .on("error", (error: any) => {
                    console.log(error)
                });
        }
    }

    return (
        <>
            <section className="main-banner">
                <div className="wrapper">
                    <div className="heroText">
                        <h2>Rise of the nano titan A saga of powers and chaos -Volume 1
                            <span className="dot"/>
                        </h2>
                        <br/>
                        <p>Author : Telmo Silva, Albert Tang, and Vivek Iyer</p>
                        <p>Edition : 1</p>
                        <p>Volume : 1</p>
                        <p>Unit available : {300 - totalSupply} ROTNT </p>
                        <p>Language : English</p>
                        <p>Price : {cost} {symbol}</p>
                        <br/>
                        <button
                            type="button"
                            className='--btn2 bannerBtn' onClick={() => mint()}>
                            Mint NFT
                        </button>
                    </div>
                    <img src={bannerimg} alt="Banner" className="bannerimg"/>
                </div>
            </section>

            {/*<section className="team-member">*/}
            {/*    <h2 className="heading text-center mb-10">Team Member</h2>*/}
            {/*    <div className="wrapper">*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team1} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Techmeup</h4>*/}
            {/*                <p>CEO</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team2} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Alexander</h4>*/}
            {/*                <p>Manager</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="teamcard">*/}
            {/*            <img src={team3} alt=""/>*/}
            {/*            <div>*/}
            {/*                <h4>Bjorn</h4>*/}
            {/*                <p>Development Head</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <Footer/>
            {/*<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>*/}
            {/*    */}
            {/*</div>*/}
        </>
    );
}

export default Home;
