import {Route, Routes} from "react-router-dom";
import Staking from "./Components/Staking/staking";
import Home from "./Components/Home/Home";
import Mint from "./Components/Minting/Mint";
import Book from "./Components/Books/book1";

const Main = () => {

    return (
        <Routes>
            <Route path="/" element={<Mint/>}/>
            <Route path="/staking" element={<Staking/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/Book" element={<Book/>}/>
        </Routes>
    )
}
export default Main;