import React, {useState, useEffect, ChangeEvent} from 'react';
import './../Staking/staking.css'
import Frontcover from '../../assets/images/books/1/Frontcover.png';
import page2 from '../../assets/images/books/1/2.png';
import page3 from '../../assets/images/books/1/3.png';
import page4 from '../../assets/images/books/1/4.png';
import page5 from '../../assets/images/books/1/5.png';
import page6 from '../../assets/images/books/1/6.png';
import page7 from '../../assets/images/books/1/7.png';
import page8 from '../../assets/images/books/1/8.png';
import page9 from '../../assets/images/books/1/9.png';
import page10 from '../../assets/images/books/1/10.png';
import page11 from '../../assets/images/books/1/11.png';
import page12 from '../../assets/images/books/1/12.png';
import page13 from '../../assets/images/books/1/13.png';
import page14 from '../../assets/images/books/1/14.png';
import page15 from '../../assets/images/books/1/15.png';
import page16 from '../../assets/images/books/1/16.png';
import page17 from '../../assets/images/books/1/17.png';
import page18 from '../../assets/images/books/1/18.png';
import page19 from '../../assets/images/books/1/19.png';
import page20 from '../../assets/images/books/1/20.png';
import page21 from '../../assets/images/books/1/21.png';
import Backcover from '../../assets/images/books/1/Backcover.png';
import Web3 from "web3";
import punks from "../../Abi/punk.json";
import IERC20 from "../../Abi/IERC20.json";
import nftstaking from "../../Abi/nftstaking.json";
import {AbiItem} from "web3-utils";
import {Footer} from "../Footer";


function Book() {
    const {ethereum} = window as any;
    const [user_ipfswallet, set_user_ipfswallet] = useState<any[]>([]);
    const [stake_nft, set_stake_nft] = useState<any[]>([]);
    const [token_id, set_token_id] = useState<any[]>([]);
    const [availableRewards, set_availableRewards] = useState('');
    const [currest_stk_balance, set_currest_stk_balance] = useState('');
    const [interest, set_interest] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setcount] = useState(0);
    const itemsPerPage = 3; // Number of items per page
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState('');
    const [nfts, setNfts] = useState([]);
    const [buffer, setBuffer] = useState(null);
    const [name, setName] = useState<string>('');
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);

    const [chain, setchain] = useState(0);
    const [address, setaddress] = useState("");
    const [fileImg, setFileImg] = useState<File | null>(null);

    const [desc, setDesc] = useState<string>('');
    const [currentPage2, setCurrentPage2] = useState(1);
    const [count2, setcount2] = useState(0);
    const itemsPerPage2 = 3; // Number of items per page
    const get_campign = async () => {
        let array: any[] = [];
        let Stake_array: any[] = [];

        const web3 = new Web3(Web3.givenProvider);
        const abi = punks;
        const IERC20_abi = IERC20.abi;
        const accounts = await web3.eth.getAccounts((error: any, accounts: any) => {
            if (!error && accounts.length > 0) {
                const userAddress = accounts[0];
                console.log(`User's Ethereum address: ${userAddress}`);
            } else {
                console.error('Error getting user address:', error);
            }
        });
        const chainId = await web3.eth.getChainId();
        setchain(chainId);
        window.ethereum.on('chainChanged', get_campign);
        if (chainId === 137) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x47701Bd6826BC29403258afec58A47EB6Ef5f0e4')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xEAE202B0D0656dD42E535a43712206c0DF0B3A3c')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0xEC245985f9a42a87d64afD8e4142a970Cf2b8e1e')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                console.log(token);
                for (let j = 0; j < token; j++) {
                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push({"id": tokenId, "image": content});

                        });
                    });
                }
                set_token_id(token);
            });
            set_stake_nft(Stake_array)

            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });

            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {
                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            array.push({"id": tokenId, "image": content});
                        });
                    });
                }
            });
            set_user_ipfswallet(array);
        } else if (chainId === 56) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xc5fbEA272748b0d6aE4C7d3D3F6B5f25c9f5db03')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                console.log(token);
                for (let j = 0; j < token; j++) {

                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push( { "id": tokenId, "image": content });

                        });
                    });


                }




                set_token_id(token);
            });
            set_stake_nft(Stake_array)

            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });

            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {

                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            console.log(content);
                            array.push( { "id": tokenId, "image": content });

                        });
                    });

                }

            });

            set_user_ipfswallet(array);
        } else if (chainId === 71402) {
            const nftstaking_abi = nftstaking;
            const punks_contract = new web3.eth.Contract(abi as AbiItem[], '0x324c749bf4be4bb07be5d3cd4ff2147d24acbfbf')
            const IERC20_contract = new web3.eth.Contract(IERC20_abi as AbiItem[], '0xA440C9a6cBbADCF0509C555939b5Cc07748f8e66')
            const nftstaking_abi_contract = new web3.eth.Contract(nftstaking as AbiItem[], '0x7FEa0ee68c5c2C52595A6228ED49ff1D70fa8902')

            const getStakedTokens = await nftstaking_abi_contract.methods.numberOfStakedTokenIDsOfAnAddress(accounts[0]).call().then(async (token: any) => {
                for (let j = 0; j < token; j++) {
                    const tokenId2 = await nftstaking_abi_contract.methods.tokenStore(j).call().then(async (tokenId: any) => {
                        const gettokenid = await punks_contract.methods.tokenURI(tokenId).call().then(async (gettokenMetadataURI: any) => {
                            if (gettokenMetadataURI.startsWith("ipfs://")) {
                                gettokenMetadataURI = `https://ipfs.io/ipfs/${gettokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(gettokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            Stake_array.push({"id": tokenId, "image": content});
                        });
                    });
                }
                set_token_id(token);
            });
            set_stake_nft(Stake_array)

            const stk_balance = await nftstaking_abi_contract.methods.numberOfTokensCurrentlyStaked().call().then(async (balance: any) => {
                set_currest_stk_balance(balance);
            });

            const stk_interest = await nftstaking_abi_contract.methods.totalAccumulatedInterest().call().then(async (balance: any) => {
                set_interest(balance);
            });

            const result2 = await punks_contract.methods.walletOfOwner(accounts[0]).call().then(async (result: any) => {
                for (let i = 0; i < result.length; i++) {
                    const tokenId2 = await punks_contract.methods.tokenOfOwnerByIndex(accounts[0], i).call().then(async (tokenId: any) => {
                        const tokenId3 = await punks_contract.methods.tokenURI(tokenId).call().then(async (tokenMetadataURI: any) => {
                            if (tokenMetadataURI.startsWith("ipfs://")) {
                                tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`
                            }
                            const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
                            const content = tokenMetadata["image"].replace('ipfs://', "ipfs/")
                            console.log(content);
                            array.push({"id": tokenId, "image": content});

                        });
                    });
                }
            });
            set_user_ipfswallet(array);
        }
        setchain(chainId);
    }


    return (
        <div className="staking-page">
            {user_ipfswallet.length > 0 ? (
               <>
                   <section className="about-project">
                       <div className="wrapper">
                           <div className="text-center">
                               {/*<h1 className="heading">*/}
                               {/*    About Project*/}
                               {/*</h1>*/}
                               <img style={{width: '65%', paddingBottom: '20px'}} src={Frontcover} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page2} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page3} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page4} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page5} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page6} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page7} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page8} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page9} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page10} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page11} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page12} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page13} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page14} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page15} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page16} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page17} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page18} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page19} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page20} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={page21} alt="" />
                               <img style={{width: '65%', paddingBottom: '20px'}} src={Backcover} alt="" />
                           </div>
                       </div>
                   </section>

                   <Footer/>
               </>
            ) : (
                <section className="about-project">
                    <div className="wrapper">
                        <div className="text-center">
                            <h3 className="">
                                Please First Stake Your NFT.
                            </h3>
                        </div>
                    </div>
                </section>
            )}


        </div>
    );
}

export default Book;