import Main from './routes'
import Header from './Components/Header/index'
import './assets/styles/App.css';
import './assets/styles/responsive.css';

function App() {
    return (
        <div>
            <Header/>
            <div className='page'>
                <div className='main-page'>
                    <Main/>
                </div>
            </div>
        </div>
    );
}

export default App;
